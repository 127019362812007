a:link {
    text-decoration: underline;
    color: white ;
  }
  
  a:visited {
    text-decoration: none;
    color: white;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  a:active {
    text-decoration: underline;
  }
table{
    border-collapse: collapse;
    color: white;
}

td{
    padding: 5px; border: solid 1px #777;
}

th{
    padding: 5px; border: solid 1px #777;
}

table, th, tr, tr{
    width: 100%;
}

.legend-enter{
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.legend-leave{
    overflow: hidden !important;
}
.App {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-flow: column;
}

.header{
  flex: 0 1 auto;
}

.body{
  flex: 1 1 auto;
}

.footer{
  flex: 0 1 auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar{
  width: 0.90em; 
}

::-webkit-scrollbar-track{
  background: hsl(5, 0%, 38%);
}

::-webkit-scrollbar-thumb{
  background: hsl(5, 0%, 17%);
  border: .25em solid hsl(5, 0%, 38%);
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover{
  background: hsl(5, 0%, 10% / 1);
}

@supports (scrollbar-color: red blue){
  *{
      scrollbar-color: hsl(5, 0%, 10%) hsl(5, 0%, 38%);
  }
}
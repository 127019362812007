.morse_text{
    letter-spacing: 2px;
}

.example-enter {
    opacity: 0.01;
    transform: translate(-20px,0);
  }
.example-enter-active {
    opacity: 1;
    transform: translate(0,0);
    transition: all 200ms ease-in;
}
  
.example-leave {
    opacity: 1;
    transform: translate(0,0);
}
  
.example-leave-active {
    opacity: 0.01;
    transform: translate(-20px,0);
    transition: all 200ms ease-in;
  }

